<template>
  <v-app>
    <v-card>
      <div class="card-header">
        <div class="card-title pt-3 px-3 d-flex justify-content-between">
          <v-row>
            <v-col cols="12" md="3" class="pt-6">
              <h2>{{ id ? "Update" : "New" }} invoice</h2>
              <p v-if="invoice.paid_for">
                for <span class="text-lg-body-1">{{invoice.paid_for}}</span>
                <span v-if="invoice.application_title" class="text-lg">-{{invoice.application_title}}</span>
              </p>
            </v-col>

          </v-row>
        </div>
      </div>

      <div class="card-body">

        <v-row>
          <v-col cols="12" md="3" class="pt-6" v-if="currentUser && currentUser.access_type == 'head'">
            <v-select
                outlined
                dense
                v-model="invoice.branch_id"
                :items="ourBranches"
                label="Branch"
                item-value="id"
                item-text="name"
            ></v-select>
          </v-col>

          <v-col cols="12" md="3" class="pt-6">
            <v-select
                outlined
                dense
                v-model="search.type"
                :items="userTypes"
                @change="onChangeType"
                label="New transaction"
                item-value="value"
                item-text="name"
            ></v-select>
          </v-col>

          <v-col cols="12" md="3" class="pt-6" v-if="search.type != 'other'">
            <span class="text-lg" v-if="invoice.paid_for">{{invoice.paid_for}}</span>
            <v-autocomplete
                outlined
                dense
                v-model="user"
                :items="items"
                :search-input.sync="search_sync"
                item-text="display_text"
                item-value="id"
                label="Name, email, business name, phone"
                placeholder="Start typing to search"
                prepend-inner-icon="mdi-database-search"
                @change="prefillUserData()"
                small-chips
                deletable-chips
                return-object
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="3" class="pt-6" v-if="search.type != 'other' && search.type != 'student'">
            <v-select
                outlined
                dense
                v-model="application"
                :items="applicationLists"
                item-text="title"
                item-value="id"
                @change="invoice.application_id = application"
                label="Application reference no"
                prepend-inner-icon="mdi-database-search"
            ></v-select>
          </v-col>

        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <v-text-field
                    outlined
                    dense
                    label="Payer name"
                    v-model="invoice.payer_name"
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.invoice.payer_name.$error"
                >This information is required</span
                >
              </v-col>

              <v-col cols="12">
                <v-text-field
                    outlined
                    dense
                    label="Payer email"
                    v-model="invoice.payer_email"
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.invoice.payer_email.$error"
                >This information is required</span
                >
              </v-col>

              <v-col cols="12">
                <v-text-field
                    outlined
                    dense
                    label="Payer phone"
                    v-model="invoice.payer_mobile"
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.invoice.payer_mobile.$error"
                >This information is required</span
                >
              </v-col>

              <v-col cols="12">
                <v-text-field
                    outlined
                    dense
                    label="Payer address"
                    v-model="invoice.payer_address"
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.invoice.payer_address.$error"
                >This information is required</span
                >
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                    label="Invoice for"
                    outlined
                    dense
                    @change="onChangeSearchType"
                    v-model="searchType"
                    :items="searchTypes"
                    item-text="name"
                    item-value="value"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                    label="Invoice to"
                    :search-input.sync="invoiceToSearch"
                    :error="$v.invoice.invoiced_to.$error"
                    :items="invoiceData"
                    item-text="display_text"
                    item-value="display_text"
                    @change="onInvoiceTo"
                    return-object
                    outlined
                    dense
                    v-model="invoiced_to"
                ></v-autocomplete>
                <span class="text-danger" v-if="$v.invoice.invoiced_to.$error"
                >This information is required</span
                >
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="Invoice email"
                    :error="$v.invoice.invoice_email.$error"
                    outlined
                    dense
                    v-model="invoice.invoice_email"
                ></v-text-field>
                <span class="text-danger" v-if="$v.invoice.invoice_email.$error"
                >This information is required</span
                >
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="Invoice phone"
                    :error="$v.invoice.invoice_phone.$error"
                    outlined
                    dense
                    v-model="invoice.invoice_phone"
                ></v-text-field>
                <span class="text-danger" v-if="$v.invoice.invoice_phone.$error"
                >This information is required</span
                >
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="Invoice address"
                    :error="$v.invoice.invoice_address.$error"
                    outlined
                    dense
                    v-model="invoice.invoice_address"
                ></v-text-field>
                <span class="text-danger" v-if="$v.invoice.invoice_address.$error"
                >This information is required</span
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                nudge-left="70"
                nudge-bottom="40"
                transition="scale-transition"
                offset-overflow
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="invoice.due_date"
                    :error="$v.invoice.due_date.$error"
                    label="Payment due date"
                    prepend-inner-icon="mdi-calendar"
                    prepend-icon=""
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="invoice.due_date"
                  @input="dateMenu = false"
                  :min="currentDate"
                  no-title
              ></v-date-picker>
            </v-menu>

            <span class="text-danger" v-if="$v.invoice.due_date.$error"
            >This information is required</span
            >
          </v-col>
          <v-col cols="12" md="6">
            <v-select
                label="Tax option"
                :items="taxTypes"
                outlined
                dense
                v-model="tax_type"
                item-value="value"
                @change="calculateTotal"
                item-text="title"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <table class="table">
              <thead>
              <tr class="px-3">
                <th class="px-3 wrap-column">Transaction heading</th>
                <th class="px-3 wrap-column">Payment type</th>
                <th class="px-3 wrap-column">Title</th>
                <th class="px-3 wrap-column">Description</th>
                <th class="px-3 wrap-column">Quantity</th>
                <th class="px-3 wrap-column">Discount amount</th>
                <th class="px-3 wrap-column">Sub Total</th>
                <th class="px-3 wrap-column">Total</th>
                <th class="px-1 text-center">Action</th>
              </tr>
              </thead>
              <tr v-for="(item, i) in invoiceItems" :key="i" style="border: hidden">
                <td class="px-3 wrap-column">
                  {{ item.transaction_head_title }}
                </td>

                <td class="px-3 wrap-column">
                  {{ item.payment_type_title }}
                </td>

                <td class="px-3 wrap-column">
                  {{ item.title ? item.title : "-" }}
                </td>

                <td class="px-3 wrap-column">
                  {{ item.description ? item.description : "-" }}
                </td>

                <td class="px-3 wrap-column">
                  {{ item.quantity }}
                </td>

                <td class="px-3 wrap-column">
                  {{ item.discount_amount ? item.discount_amount : 0 }}
                </td>

                <td class="px-3 wrap-column">
                  {{ item.sub_total }}
                </td>

                <td class="px-3 wrap-column">
                  {{ item.item_price }}
                </td>
                <td class="pr-0 text-center">
                  <v-icon @click="removeItem(i, item.id)" color="red" small>fas fa-trash</v-icon>
                </td>
              </tr>
              <tr v-if="invoiceItems.length < 1">
                <td class="text-center" colspan="15">Data not available</td>
              </tr>

            </table>
            <hr />
          </v-col>

          <v-col cols="12">
            <table class="table" style="background-color: rgb(242 254 247)">
              <thead>
              <tr style="border: hidden">
                <td colspan="3" class="pt-5"><h5>Add invoice item</h5></td>
                <td colspan="6" class="pr-5 text-right">
                  <v-btn
                      class="btn-primary btn-xs float-right btn text-white"
                      @click="addNewTransactionHeading(invoiceItem)"
                  >
                    Add
                  </v-btn>
                </td>
              </tr>
              </thead>

              <tbody>
                <tr style="border: hidden; background-color: rgb(242 254 247) !important">
                <td class="px-3 pt-6 py-5" >
                  <v-select
                      v-model="invoiceItem.financial_transaction_head"
                      placeholder="Transaction heading"
                      :items="financial_heads"
                      item-text="title"
                      outlined
                      dense
                      @change="getPaymentType($event)"
                      return-object
                  ></v-select>
                  <span
                      class="text-danger"
                      v-if="
                        !invoiceItem.financial_transaction_head_id &&
                        $v.invoiceItem.financial_transaction_head_id.$error
                      "
                  >This information is required</span
                  >
                </td>

                <td class="px-3 pt-6 py-5" >
                  <v-select
                      v-model="invoiceItem.financial_payment_type_id"
                      placeholder="Payment type"
                      :items="financial_payment_types"
                      item-text="title"
                      item-value="id"
                      outlined
                      dense
                      @change="getPaymentTypeById(invoiceItem.financial_payment_type_id, 'invoice')"
                  ></v-select>
                  <span
                      class="text-danger"
                      v-if="
                        !invoiceItem.financial_payment_type_id &&
                        $v.invoiceItem.financial_payment_type_id.$error
                      "
                  >This information is required</span
                  >
                </td>

                <td class="px-3 pt-6 py-5"  v-if="invoiceItem.financial_payment_type_id == 'other'">
                  <v-text-field
                      outlined
                      dense
                      v-model="invoiceItem.title"
                      placeholder="Title"
                  ></v-text-field>
                  <span
                      class="text-danger"
                      v-if="
                        !invoiceItem.financial_payment_type_id == 'other' &&
                        $v.invoiceItem.title.$error
                      "
                  >This information is required</span
                  >
                </td>

                <td class="px-3 pt-6 py-5" >
                  <v-textarea
                      placeholder="Description"
                      outlined
                      rows="2"
                      dense
                      v-model="invoiceItem.description"
                  ></v-textarea>
                </td>

                  <td class="px-3 pt-6 py-5" >
                    <v-select
                        @change="getPaymentTypeById(invoiceItem.financial_payment_type_id, 'invoice')"
                        outlined
                        dense
                        :items="['fixed', 'percentage']"
                        clearable
                        label="Discount type"
                        v-model="invoiceItem.discount_type"
                    ></v-select>
                  </td>
                  <td class="px-3 pt-6 py-5"  v-if="invoiceItem.discount_type">
                      <v-text-field
                          @blur="validateItemDiscountAmount"
                          v-if="invoiceItem.discount_type == 'fixed'"
                          type="number"
                          outlined
                          dense
                          label="Discount amount"
                          v-model="invoiceItem.discount_amount"
                      >
                      </v-text-field>
                      <v-text-field
                          @blur="validateItemDiscountAmount"
                          v-if="invoiceItem.discount_type == 'percentage'"
                          type="number"
                          outlined
                          dense
                          label="Discount percentage"
                          v-model="invoiceItem.discount_percentage"
                      >
                      </v-text-field>
                  </td>

                <td class="px-3 pt-6 py-5" >
                  <v-text-field
                      @input="calculateQuantity"
                      type="number"
                      min="0"
                      placeholder="Qty"
                      outlined
                      dense
                      v-model="invoiceItem.quantity"
                  ></v-text-field>
                  <span
                      class="text-danger"
                      v-if="!invoiceItem.quantity && $v.invoiceItem.quantity.$error"
                  >This information is required</span
                  >
                </td>


                <td class="px-3 pt-6 py-5" >
                  <v-text-field
                      type="number"
                      min="0"
                      placeholder="Sub total"
                      outlined
                      dense
                      v-model="invoiceItem.sub_total"
                  ></v-text-field>
                </td>

                  <td class="px-3 pt-6 py-5" >
                  <v-text-field
                      type="number"
                      min="0"
                      outlined
                      dense
                      placeholder="Amount"
                      v-model="invoiceItem.item_price"
                  ></v-text-field>
                  <span
                      class="text-danger"
                      v-if="!invoiceItem.item_price && $v.invoiceItem.item_price.$error"
                  >This information is required</span
                  >
                </td>
              </tr>
              </tbody>
            </table>
          </v-col>

          <v-col cols="12">
            <table class="table" style="background-color: rgb(240 240 240)">
              <thead>
              <tr class="pt-5" style="border: hidden">
                <td colspan="2"><h5>Add discount</h5></td>
              </tr>
              </thead>
              <tbody>
              <tr
                  style="border: hidden; background-color: rgb(240 240 240) !important"
              >
                <v-row class="mb-3 mt-4 mx-3">
                  <v-col cols="12" md="6">
                    <div @input="validateDiscountAmount">
                      <v-select
                          outlined
                          dense
                          :items="['fixed', 'percentage']"
                          label="Discount type"
                          v-model="invoice.discount_type"
                      ></v-select>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <div v-if="invoice.discount_type">
                      <v-text-field
                          @blur="validateDiscountAmount"
                          v-if="invoice.discount_type == 'fixed'"
                          type="number"
                          outlined
                          dense
                          label="Discount amount"
                          v-model="invoice.discount_amount"
                      >
                      </v-text-field>
                      <v-text-field
                          @input="validateDiscountAmount"
                          v-if="invoice.discount_type == 'percentage'"
                          type="number"
                          outlined
                          dense
                          label="Discount percentage"
                          v-model="invoice.discount_percentage"
                      >
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </tr>
              </tbody>
            </table>
          </v-col>

          <v-col cols="12" md="6">
          </v-col>

          <v-col cols="12" md="6" class="text-right">
            <table class="table">
              <tr>
                <th class="text-right">Sub total</th>
                <td class="text-right">{{ currencySymbol }} {{ invoice.sub_total || "00.00" }}</td>
              </tr>
              <tr>
                <th class="text-right">Discount</th>
                <td class="text-right">{{ currencySymbol }}  {{ invoice.discount_amount || "00.00" }}</td>
              </tr>

              <tr v-if="invoice.discount_amount">
                <th class="text-right">After discount</th>
                <td class="text-right">
                  {{ currencySymbol }} {{
                    parseFloat(invoice.sub_total) - parseFloat(invoice.discount_amount) ||
                    "00.00"
                  }}
                </td>
              </tr>
              <tr>
                <th class="text-right">Tax({{ mySiteSetting.tax_percentage || 0 }}%)</th>
                <td class="text-right">{{ currencySymbol }} {{ invoice.gst_amount || "00.00" }}</td>
              </tr>


              <tr>
                <th class="text-right">Total</th>
                <td class="text-right">
                  <span style="font-size: 18px; font-weight: bold">
                    {{ currencySymbol }} {{ invoice.total_amount || "00.00" }}</span
                  >
                </td>
              </tr>
            </table>
          </v-col>

          <v-col cols="12">
            <div
                class="rounded"
                style="background-color: rgb(240 240 240) !important"
            >
              <v-textarea
                  rows="9"
                  outlined
                  dense
                  label="Remark"
                  v-model="invoice.remarks"
              >
              </v-textarea>
            </div>
          </v-col>

        </v-row>

        <div class="row">
          <div class="col-12">
            <v-btn
                class="btn btn-primary text-white float-right"
                @click="validateForm()"
            >
              {{ id ? "Update" : "Save" }}
            </v-btn>
          </div>
        </div>

      </div>
    </v-card>
  </v-app>
</template>

<script>
import FinanceMixin from "@/mixins/FinanceMixin";
import {email, required } from "vuelidate/lib/validators";
import UserService from "@/services/user/UserService";
import ApplicationService from "@/services/application/ApplicationService";
import InvoiceService from "@/services/invoice/InvoiceService";
import InvoiceItemService from "@/services/invoice/item/InvoiceItemService";
import InstitutionService from "@/services/academic/institution/InstitutionService";

const userService = new UserService();
const application = new ApplicationService();
const invoiceService = new InvoiceService();
const institution = new InstitutionService();
const invoiceItemService = new InvoiceItemService();

export default {
  name: "CreateOrUpdate",
  mixins: [FinanceMixin],
  validations() {
    return {
      invoice: this.invoiceRules,
      invoiceItem: this.invoiceItemRules,
    };
  },
  data() {
    return {
      user: {},
      application: {},
      invoiced_to: {},
      user_lists: [],
      applicationLists: [],
      invoiceItems: [],
      searchData: [],
      isLoading: false,
      invoiceLoading: false,
      dateMenu: false,
      descriptionLimit: 60,
      tax_type: "inclusive",
      searchType: "agent",
      search_sync: null,
      invoiceToSearch: null,
      currencySymbol: 'Rs',
      paymentDetail: {},
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      invoice: {
        payment_id: '',
        user_id: '',
        application_id: '',
        branch_id: '',
        type: 'invoice',
        file: '',
        invoiced_to: '',
        invoice_email: '',
        invoice_phone: '',
        invoice_address: '',
        payer_name: '',
        payer_email: '',
        payer_mobile: '',
        payment_method: 'bank',
        sub_total: '',
        discount_amount: '',
        gst_amount: '',
        total_amount: '',
      },
      invoiceItem: {
        financial_transaction_head_id: '',
        financial_payment_type_id: '',
        title: '',
        description: '',
        discount_amount: '',
        quantity: '',
        sub_total: '',
        item_price: '',
      },
      search: {
        type: "student",
        name: "",
      },
      filter: {
        application_created_by: "",
        applicant_id: "",
        is_draft: 0,
        is_ready_to_submit: 1
      },
      taxTypes: [
        { title: "Tax Inclusive", value: "inclusive" },
        { title: "Tax Exclusive", value: "exclusive" },
        { title: "No Tax", value: "no" },
      ],
      userTypes: [
        { name: "Student", value: "student" },
        { name: "Agent", value: "agent" },
        { name: "Onshore", value: "onshore" },
        { name: "Other", value: "other" },
      ],
      searchTypes: [
        { name: "Student", value: "student" },
        { name: "Agent", value: "agent" },
        { name: "Institution", value: "institution" },
      ],
      payment_types: [
        {
          title: "Quote",
          value: "pending",
        },
        {
          title: "Invoice",
          value: "paid",
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.user_lists.map((user) => {
        let display_text;
        if(user.business_name) {
          display_text = user.full_name+' - '+ user.business_name;
        } else {
          display_text = user.full_name;
        }
        return Object.assign({}, user, { display_text });
      });
    },

    invoiceData() {
      return this.searchData.map((item) => {
        let display_text;
        if(this.searchType == 'agent' || this.searchType == 'student') {
          display_text = item.full_name;
        } else {
          display_text = item.title;
        }
        return Object.assign({}, item, { display_text });
      });
    },

    invoiceRules() {
      return {
        invoiced_to: { required },
        invoice_email: { required },
        invoice_phone: { required },
        invoice_address: { required },
        payment_method: { required },
        due_date: { required },
        payer_name: { required },
        payer_email: { required, email},
        payer_mobile: { required },
        payer_address: { required },
      };
    },
    invoiceItemRules() {
      return {
        financial_transaction_head_id: { required },
        financial_payment_type_id: { required },
        quantity: { required },
        item_price: { required },
      };
    },
  },
  watch: {
    search_sync(val) {
      if(this.isLoading) return;
      this.isLoading = true
      this.search.name = val;
      userService
          .getAllByUserType(this.search)
          .then((response) => {
            this.user_lists = response.data.users;
          })
          .catch(() => { })
          .finally(() => (this.isLoading = false));
    },
    invoiceToSearch(val) {
      if(this.invoiceLoading) return;
      this.invoiceLoading = true
      this.searchData = [];
      if (this.searchType == 'agent' || this.searchType == 'student') {
        userService
            .getAllByUserType({name: val, type: this.searchType})
            .then((response) => {
              this.searchData = response.data.users;
              this.invoiceLoading = false
            })
            .catch(() => { this.invoiceLoading = false });
      } else {
        institution
              .search(val)
              .then((response) => {
                this.searchData = response.data.universities;
                this.invoiceLoading = false
              })
              .catch(() => { this.invoiceLoading = false });
      }
    },
  },
  mounted() {
    if(this.id) {
      this.getInvoice();
    }
    this.getAllActiveTransactionHead('invoice');
    this.getAllBranches()
    if(this.currentUser && this.currentUser.access_type != 'branch') {
      this.invoice.branch_id = this.currentUser.branch_id;
    }
  },
  methods: {
    onChangeSearchType() {
      this.searchData = [];
      this.invoiced_to = {};
      this.currencySymbol = 'Rs';
      this.invoice.invoiced_to = null;
      this.invoice.invoice_email = null;
      this.invoice.invoice_phone = null;
      this.invoice.invoice_address = null;
    },

    onInvoiceTo() {
      this.currencySymbol = this.invoiced_to.currency_symbol || 'Rs';
      this.invoice.invoiced_to = this.invoiced_to.display_text || null;
      this.invoice.invoice_email = this.invoiced_to.email || this.invoiced_to.university_email;
      this.invoice.invoice_phone = this.invoiced_to.mobile || this.invoiced_to.phone_no;
      this.invoice.invoice_address = this.invoiced_to.address || null;
    },

    onChangeType() {
      this.user = {};
      this.invoice.applicant_id = null;
      this.invoice.application_id = null;
      this.invoice.payer_name = null;
      this.invoice.payer_mobile = null;
      this.invoice.payer_email = null;
      this.invoice.payer_user_id = null;
      this.applicationLists = [];
      this.user_lists = [];
    },

    getAllApplication() {
      application
          .filterApplications(this.filter)
          .then(response => {
            this.applicationLists = response.data.data;
          })
          .catch(() => {
          });
    },

    prefillUserData() {
      this.invoice.payer_name = this.mySiteSetting.company_name+' on behalf of '+ `(${this.user.full_name})`;
      this.invoice.payer_mobile = this.mySiteSetting.phone;
      this.invoice.payer_address = this.mySiteSetting.address;
      this.invoice.payer_email = this.mySiteSetting.email;
      this.invoice.payer_user_id = this.user.id;
      if(this.search.type === 'agent') {
        this.filter.application_created_by = this.user.id;
      } else {
        this.filter.applicant_id = this.user.id;
      }
      this.getAllApplication();
    },

    addNewTransactionHeading(item) {
      this.$v.invoiceItem.$touch();
      if (this.$v.invoiceItem.$error) {
        setTimeout(() => {
          this.$v.invoiceItem.$reset();
        }, 3000);
      } else {
        let exist = false;
        if (this.invoiceItems.length > 0) {
          this.invoiceItems.forEach((ele) => {
            if (
                item.financial_payment_type_id == ele.financial_payment_type_id &&
                item.financial_transaction_head_id == ele.financial_transaction_head_id
            ) {
              this.$snotify.error("Item already exist in list");
              exist = true;
            }
          });
        }
        if (!exist) {
          this.invoiceItems.push(item);
        }
        this.resetInvoiceItem();
      }
    },

    resetInvoiceItem() {
      this.invoiceItem = {
        financial_transaction_head_id: '',
        financial_payment_type_id: '',
        title: '',
        description: '',
        discount_amount: '',
        quantity: '',
        sub_total: '',
        item_price: '',
      };
      this.calculateTotal();
      this.$v.invoiceItem.$reset();
    },

    removeItem(index, id = null) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            if (id) {
              invoiceItemService.delete(id).then(() => {
                this.$snotify.success("Item Information deleted");
              });
            }
            this.invoiceItems.splice(index, 1);
            this.calculateTotal();
          }
        },
      });
    },

    getInvoice() {
      invoiceService.show(this.id).then((response) => {
        this.invoice = response.data.invoice;
        if(this.invoice.is_agent) {
          this.search.type = 'agent';
        }
        if(this.invoice.is_agent) {
          this.search.type = 'student';
        }
        if(this.invoice.is_agent) {
          this.search.type = 'other';
        }
        this.getInvoiceItems();
      });
    },

    getInvoiceItems() {
      invoiceItemService.getAllByinvoice(this.invoice.id).then((response) => {
        if(response.data.data) {
          this.invoiceItem = response.data.data;
          this.calculateTotal();
        }
      });
    },

    validateDiscountAmount() {
      this.removeCashReturn();
      if (
          this.invoice.discount_type == "fixed" &&
          parseFloat(this.invoice.discount_amount) < parseFloat(this.invoice.sub_total)
      ) {
        this.calculateTotal();
      } else if (this.invoice.discount_type == "percentage") {
        this.calculateTotal();
      } else {
        this.invoice.discount_amount = 0;
        this.$snotify.error("Discount amount cannot be more than actual amount");
        this.calculateTotal();
      }
    },

    calculateQuantity() {
      if (parseInt(this.invoiceItem.quantity) > 1) {
        this.calculateItemTotal();
      } else {
        this.$snotify.error("Quantity cannot be less than zero");
        this.getPaymentTypeById(this.invoiceItem.financial_payment_type_id, 'invoice');
      }
    },


    validateItemDiscountAmount() {
      if (this.invoiceItem.discount_type == "fixed" && parseFloat(this.invoiceItem.discount_amount) < parseFloat(this.invoiceItem.sub_total)) {
        this.calculateItemTotal();
      } else if (this.invoiceItem.discount_type == "percentage"  && this.invoiceItem.discount_percentage) {
        this.calculateItemTotal();
      } else {
        this.invoiceItem.discount_amount = 0;
        this.getPaymentTypeById(this.invoiceItem.financial_payment_type_id, 'invoice');
      }
    },

    calculateItemTotal() {
      let subtotal = this.invoiceItem.quantity * this.invoiceItem.sub_total;
      if (!this.invoiceItem) return;
      if (this.invoiceItem.discount_amount && this.invoiceItem.discount_type == "fixed") {
        subtotal = parseFloat(subtotal) - parseFloat(this.invoiceItem.discount_amount);
      }
      if (this.invoiceItem.discount_percentage && this.invoiceItem.discount_type == "percentage" ) {
        this.invoiceItem.discount_amount = (parseFloat(subtotal) * parseFloat(this.invoiceItem.discount_percentage)) / 100;
        subtotal = parseFloat(subtotal) - parseFloat(this.invoiceItem.discount_amount);
      }
      this.invoiceItem.discount_amount = Number(this.invoiceItem.discount_amount).toFixed(2);
      this.invoiceItem.item_price = Number(subtotal).toFixed(2);
    },

    removeCashReturn() {
      this.calculateTotal();
    },

    calculateTotal() {
      let subtotal = 0;
      if (this.invoiceItems.length < 0) return;
      let taxPercentage = this.mySiteSetting.tax_percentage || 0;
      this.invoiceItems.forEach((ele) => {
        subtotal += parseFloat(ele.item_price);
      });
      this.invoice.sub_total = subtotal;
      if (this.invoice.discount_amount && this.invoice.discount_type == "fixed") {
        subtotal = parseFloat(subtotal) - parseFloat(this.invoice.discount_amount);
      }
      if (this.invoice.discount_percentage && this.invoice.discount_type == "percentage" ) {
        this.invoice.discount_amount = (parseFloat(subtotal) * parseFloat(this.invoice.discount_percentage)) / 100;
        subtotal = parseFloat(subtotal) - parseFloat(this.invoice.discount_amount);
      }

      switch (this.tax_type) {
        default:
          this.invoice.gst_amount = 0;
          this.invoice.gst_percentage = null;
          break;

        case "inclusive":
          this.invoice.gst_amount = parseFloat(subtotal) * (parseFloat(taxPercentage) / 100);
          this.invoice.gst_percentage = taxPercentage;
          break;
      }
      this.invoice.gst_amount = Number(this.invoice.gst_amount).toFixed(2);
      this.invoice.discount_amount = Number(this.invoice.discount_amount).toFixed(2);
      this.invoice.sub_total = Number(this.invoice.sub_total).toFixed(2);
      this.invoice.total_amount = parseFloat(subtotal) + parseFloat(this.invoice.gst_amount);
      this.invoice.total_amount = Number(this.invoice.total_amount).toFixed(2);
    },

    validateForm() {
      this.$v.invoice.$touch();
      if (this.$v.invoice.$error) {
        setTimeout(() => {
          this.$v.invoice.$reset();
        }, 3000);
      } else {
        this.invoice.items = this.invoiceItems;
        if (this.id) {
          this.updateInvoice();
        } else {
          this.saveInvoice();
        }
      }
    },

    saveInvoice() {
      invoiceService.store(this.invoice).then((response) => {
        if(response.data.status == "OK") {
          this.redirectTo();
        }
      });
    },

    updateInvoice() {
      invoiceService.update(this.id, this.invoice).then((response) => {
        if(response.data.status == "OK") {
          this.redirectTo();

        }
      });
    },

    redirectTo() {
      this.$tabs.close().then(() => {
        this.$snotify.success("Information updated");
        this.$tabs.open({ name: "invoice" });
      });
    },
  },
};
</script>
