import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class InvoiceItemService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}admin/invoice`;
    }

    getAllInvoiceItems() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }

    store(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

}
